
<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Reporte de Documentos creados por usuario</h1>
        </div>
        <br>
        <form v-on:submit.prevent="searchItems(item)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Usuario (*):</label>
                <select ref="usu_id" v-model="item.usu_id" class="form-control col-md-8" v-bind:disabled="userDisabled" required>
                  <option v-for="i in usuarios" v-bind:value="i._id">
                    {{ i.usu_nmbre + ' ' + i.usu_aplldo }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Clase de documento (*):</label>
                <select v-model="item.tcr_id" ref="tcr_id" class="form-control col-md-8" required>
                  <option v-for="i in tpocors" v-bind:value="i._id">
                    {{ i.tcr_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Dependencia:</label>
                <select v-model="item.dep_id" class="form-control col-md-8">
                  <option v-for="i in dependencias" v-bind:value="i._id">
                    {{ i.dep_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha inicial (desde) (*):</label>
                <input type="date" class="form-control col-md-8" v-model="item.dcm_fcharadini" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha final (hasta) (*):</label>
                <input type="date" class="form-control col-md-8" v-model="item.dcm_fcharadfin" required>
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-6">
              <button type="submit" class="btn btn-primary">Consultar</button>
            </div>
            <div class="col-6">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';

  export default {
    data(){
      return{
        item: {},
        message: '',
        tpocors: [],
        usuarios: [],
        dependencias: [],
        debug: '',
        userDisabled: true
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.fetchUsuario();
      this.fetchTpocor();
      //this.fetchDependencia();
    },
    mounted: function(){

    },
    methods: {
      fetchUsuario()
      {
        let uri = '/usuarios/' + String(this.$store.state.company);
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.usu_nmbre > b.usu_nmbre) { return 1; };
            if (a.usu_nmbre < b.usu_nmbre) { return -1; };
            return 0;
          });
          this.usuarios = list;
          this.item.usu_id = String(this.$store.state.user);

          let uri = '/tpousus/edit/' + String(this.$store.state.userdata.type);
          this.axios.get(uri)
          .then(response => {
            if (response.data.tus_cdgo >= 0 && response.data.tus_cdgo <= 2){
              this.userDisabled = false;
            }
          });
        });
      },
      fetchTpocor()
      {
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.tcr_nmbre > b.tcr_nmbre) { return 1; };
            if (a.tcr_nmbre < b.tcr_nmbre) { return -1; };
            return 0;
          });
          this.tpocors = list;
        });
      },
      fetchDependencia()
      {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.dep_nmbre > b.dep_nmbre) { return 1; };
            if (a.dep_nmbre < b.dep_nmbre) { return -1; };
            return 0;
          });
          this.dependencias = list;
        });
      },
      searchItems(q){
        var p = {};
        p.query = q;
        p.username = this.$refs.usu_id.options[this.$refs.usu_id.selectedIndex].text;
        this.$router.replace({ name: 'ReportDocsRadUsuario', params: p });
      },
      cancel(){
        this.$router.replace({ name: 'Home' });
      }

    } //END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
